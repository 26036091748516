import React, { useEffect, useState } from "react";
import { RouteInfo, routeInfotypeList } from "../../../models/Ndtrc";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import classes from "./RouteInfoWidget.module.scss";
import { FaSync } from "react-icons/fa";
import { feedFactoryAxios } from "../../../helpers/feedFactoryAxios";
import { useLocation } from "react-router";

const RouteInfoWidget = (props: {
  value: RouteInfo;
  onChange: (value: RouteInfo) => any;
}) => {
  const { t } = useTranslation();
  const [syncing, setSyncing] = useState(false);
  const [syncSuccess, setSyncSuccess] = useState(false);
  const [syncError, setSyncError] = useState(false);

  const [hasError, setHasError] = React.useState<boolean>(false);
  const typeOptions = routeInfotypeList.map((type) => {
    return {
      value: type,
      label: t(`routeInfo.type.${type}`),
    };
  });

  const path = useLocation().pathname;
  const pathId = path.split("/").pop();

  useEffect(() => {
    // Check validity of the values
    if (
      (props.value.distanceInKilometers !== null &&
        props.value.distanceInKilometers <= 0) ||
      (props.value.durationInMinutes !== null &&
        props.value.durationInMinutes <= 0)
    ) {
      setHasError(true);
      return;
    }
    setHasError(false);
  }, [props.value]);

  const handleSyncWithRouteMaker = async () => {
    setSyncing(true);
    setSyncSuccess(false);
    setSyncError(false);
    try {
      await feedFactoryAxios.post(`/routes/${pathId}/syncwithexternal`);
      setSyncSuccess(true);
      setTimeout(() => {
        setSyncSuccess(false);
      }, 3000);
    } catch (error) {
      console.error("Error syncing with RouteMaker:", error);
      setSyncError(true);
      setTimeout(() => {
        setSyncError(false);
      }, 3000);
    } finally {
      setSyncing(false);
    }
  };

  console.log(syncSuccess, syncError);

  return (
    <div className={classes.RouteInfoWidget}>
      <div className={classes.header}>
        {props.value.type === "route_maker" && (
          <button
            onClick={handleSyncWithRouteMaker}
            disabled={syncing}
            className={`${classes.syncButton} ${
              syncSuccess ? classes.success : ""
            } ${syncError ? classes.error : ""}`}
          >
            <FaSync />
            {syncing
              ? t("route.syncing")
              : syncSuccess
              ? t("route.syncSuccess")
              : syncError
              ? t("route.syncError")
              : t("route.syncWithRouteMaker")}
          </button>
        )}
      </div>

      <label>{t("routeInfo.type")}</label>
      <Select
        value={typeOptions.find((option) => option.value === props.value.type)}
        isClearable
        options={typeOptions}
        menuPlacement={"auto"}
        placeholder={`${t("form.select")}...`}
        styles={{
          menu: (provided: any, state) => {
            return { ...provided, zIndex: 2 };
          },
        }}
        onChange={(item: any) => {
          if (item) {
            props.onChange({ ...props.value, type: item.value });
          }
        }}
        defaultValue={typeOptions.find(
          (option) => option.value === "route_maker"
        )}
      />

      <label>{t("routeInfo.url")}</label>
      <input
        value={props.value.url || ""}
        onChange={(e) => {
          props.onChange({ ...props.value, url: e.currentTarget.value });
        }}
      />
      {/* <div className={classes.buttonArray}>
        <TextButton action={importRouteInfo}>
          <LiaFileImportSolid /> {t("routeInfo.import")}
        </TextButton>
      </div> */}

      <div className={classes.twoColumns}>
        <div>
          <label>{t("routeInfo.distanceInKilometers")}</label>

          <input
            type="number"
            value={props.value.distanceInKilometers?.toString() || ""}
            onChange={(e) => {
              props.onChange({
                ...props.value,
                distanceInKilometers: e.currentTarget.value
                  ? parseFloat(e.currentTarget.value)
                  : null,
              });
            }}
          />
        </div>
        <div>
          <label>{t("routeInfo.durationInMinutes")}</label>
          <input
            type="number"
            value={props.value.durationInMinutes?.toString() || ""}
            onChange={(e) => {
              props.onChange({
                ...props.value,
                durationInMinutes: e.currentTarget.value
                  ? parseInt(e.currentTarget.value)
                  : null,
              });
            }}
          />
        </div>
      </div>

      {hasError && (
        <div className={classes.errorMessage}>{t("routeInfo.error")}</div>
      )}
    </div>
  );
};

export default RouteInfoWidget;
